<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="selectedValue"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="selectedValue"
        :label="label"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="rules"
        dense
        :disabled="$attrs.disabled"
      />
    </template>
    <v-date-picker
      v-model="selectedValue"
      no-title
      scrollable
      :max="date_max"
      :min="date_min"
    >
      <v-spacer />
      <BtnCircle
        tLabel="Cancelar"
        tPosition="top"
        icon="mdi-close"
        color="default"
        size="x-small"
        :click="handleCancel"
      />
      <BtnCircle
        v-if="enableClean"
        tLabel="Limpiar"
        tPosition="top"
        icon="mdi-eraser"
        color="secondary"
        size="x-small"
        :click="handleClean"
      />
      <BtnCircle
        tLabel="Aceptar"
        tPosition="top"
        icon="mdi-check"
        color="info"
        size="x-small"
        :click="handleAccept"
      />
    </v-date-picker>
  </v-menu>
</template>

<script>
//import components
import BtnCircle from "./BtnCircle.vue";

export default {
  components: {
    BtnCircle,
  },
  props: ["label", "model", "rules"],
  data() {
    return {
      menu: false,
      enableClean: typeof this.$attrs.clean !== "undefined" ? true : false,
      disabled:
        typeof this.$attrs.disabled !== "undefined"
          ? this.$attrs.disabled
          : false,
      enableAfterDate:
        typeof this.$attrs.enableAfterDate !== "undefined" ? true : false,
      enableAllDate: typeof this.$attrs.all_date !== "undefined" ? true : false,
      date_max: "",
      date_min: "",
    };
  },
  computed: {
    selectedValue: {
      get: function () {
        return this.model;
      },
      set: function (new_value) {
        this.$emit("update:model", new_value);
      },
    },
  },
  methods: {
    handleCancel() {
      this.menu = false;
    },
    handleClean() {
      this.$refs.menu.save(null);
    },
    handleAccept() {
      this.$refs.menu.save(this.selectedValue);
    },
  },
  mounted() {
    this.date_min = "";
    this.date_max = "";

    if (!this.enableAllDate) {
      const old = "1900-01-01";
      const now = new Date(
        Date.now() -
          (this.enableAfterDate ? 0 : new Date().getTimezoneOffset() * 60000)
      )
        .toISOString()
        .substr(0, 10);

      this.date_min = this.enableAfterDate ? now : old;
      this.date_max = this.enableAfterDate ? "" : now;
    }
  },
};
</script>